import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './components/layout/layout.component';
import { AudienceGuard } from './guards/audience.guard';
import { AuthGuard } from './guards/auth.guard';
import { CertifyingExamReviewGuard } from './guards/certifying-exam-review.guard';
import { GeneralExamReviewGuard } from './guards/general-exam-review.guard';
import { MemberActiveGuard } from './guards/member-active.guard';
import { AuthCallbackComponent } from './pages/auth-callback/auth-callback.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { CmsPageComponent } from './pages/cms-page/cms-page.component';
import { DiplomateReevaluationReviewComponent } from './pages/diplomate-reevaluation/diplomate-reevaluation-review/diplomate-reevaluation-review.component';
import { DiplomateReevaluationReviewOverviewComponent } from './pages/diplomate-reevaluation/diplomate-reevaluation-review-overview/diplomate-reevaluation-review-overview.component';
import { DiplomateReevaluationSubmissionComponent } from './pages/diplomate-reevaluation/diplomate-reevaluation-submission/diplomate-reevaluation-submission.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { CertifyingExamApplicationSubmissionComponent } from './pages/exam-application/certifying-exam-application-submission/certifying-exam-application-submission.component';
import { CertifyingExamReviewComponent } from './pages/exam-application/certifying-exam-review/certifying-exam-review.component';
import { ExamReviewOverviewComponent } from './pages/exam-application/exam-review-overview/exam-review-overview.component';
import { GeneralExamApplicationSubmissionComponent } from './pages/exam-application/general-exam-application-submission/general-exam-application-submission.component';
import { GeneralExamReviewComponent } from './pages/exam-application/general-exam-review/general-exam-review.component';
import { HomeComponent } from './pages/home/home.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DiplomateReevaluationsAsReevaluationsChairResolver } from './resolvers/diplomate-reevaluation/diplomate-reevaluations-as-reevaluations-chair.resolver';
import { DiplomateReevaluationsAsReviewerResolver } from './resolvers/diplomate-reevaluation/diplomate-reevaluations-as-reviewer.resolver';
import { DiplomateReevaluationsForMemberResolver } from './resolvers/diplomate-reevaluation/diplomate-reevaluations-for-member.resolver';
import { CertifyingExamForReviewByIdResolver } from './resolvers/exam-application/certifying-exam-for-review-by-id.resolver';
import { CertifyingExamsAsCredentialsChairResolver } from './resolvers/exam-application/certifying-exams-as-credentials-chair.resolver';
import { CertifyingExamsAsReviewerResolver } from './resolvers/exam-application/certifying-exams-as-reviewer.resolver';
import { CertifyingExamsForMemberResolver } from './resolvers/exam-application/certifying-exams-for-member.resolver';
import { GeneralExamForReviewByIdResolver } from './resolvers/exam-application/general-exam-for-review-by-id.resolver';
import { GeneralExamsAsCredentialsChairResolver } from './resolvers/exam-application/general-exams-as-credentials-chair.resolver';
import { GeneralExamsAsReviewerResolver } from './resolvers/exam-application/general-exams-as-reviewer.resolver';
import { GeneralExamsForMemberResolver } from './resolvers/exam-application/general-exams-for-member.resolver';
import { MemberResolverService } from './resolvers/member.resolver.service';
import { MenuResolverService } from './resolvers/menu.resolver.service';
import { NewsResolverService } from './resolvers/news.resolver.service';
import { PageResolverService } from './resolvers/page.resolver.service';
import { PaymentResolverService } from './resolvers/payment.resolver.service';

const routes: Routes = [
  // SPECIAL ROUTES
  {
    path: 'auth/callback',
    component: AuthCallbackComponent,
  },

  // NORMAL ROUTES
  {
    path: '',
    component: LayoutComponent,
    resolve: {
      member: MemberResolverService,
      menu: MenuResolverService,
    },
    children: [
      // ROUTES WITHOUT AUTHENTICATION
      {
        path: 'termsandconditions',
        component: CmsPageComponent,
        resolve: {
          page: PageResolverService,
        },
      },
      {
        path: 'privacypolicy',
        component: CmsPageComponent,
        resolve: {
          page: PageResolverService,
        },
      },
      {
        path: 'legal-notice',
        component: CmsPageComponent,
        resolve: {
          page: PageResolverService,
        },
      },

      // ROUTES THAT REQUIRE AUTHENTICATION
      {
        path: '',
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        children: [
          // ROUTES THAT REQUIRE ACTIVE MEMBER
          {
            path: '',
            canActivate: [MemberActiveGuard],
            children: [
              {
                path: '',
                component: HomeComponent,
                resolve: {
                  news: NewsResolverService,
                },
              },

              {
                path: 'user',
                children: [
                  {
                    path: 'profile',
                    component: ProfileComponent,
                    data: {
                      title: 'My Profile',
                    },
                  },
                  {
                    path: 'documents',
                    component: DocumentsComponent,
                    data: {
                      title: 'My Documents',
                    },
                  },
                  {
                    path: 'payments',
                    component: PaymentsComponent,
                    data: {
                      title: 'My Payments',
                    },
                  },
                  {
                    path: 'checkout/:id',
                    component: CheckoutComponent,
                    resolve: {
                      payment: PaymentResolverService,
                    },
                    data: {
                      title: 'My Payments',
                    },
                  },
                ],
              },

              {
                path: 'committee-work/diplomate-re-evaluations-review',
                component: DiplomateReevaluationReviewOverviewComponent,
                canActivate: [AudienceGuard],
                data: {
                  audience: ['ComponentMasterdataDiplomat'],
                },
                resolve: {
                  diplomateReevaluationsAsReviewer: DiplomateReevaluationsAsReviewerResolver,
                  diplomateReevaluationsAsReevaluationsChair: DiplomateReevaluationsAsReevaluationsChairResolver,
                },
              },

              {
                path: 'committee-work/review-diplomate-re-evaluation',
                component: DiplomateReevaluationReviewComponent,
                canActivate: [AudienceGuard],
                data: {
                  audience: ['ComponentMasterdataDiplomat'],
                },
              },

              {
                path: 'committee-work/credentials-review',
                component: ExamReviewOverviewComponent,
                canActivate: [AudienceGuard],
                data: {
                  audience: ['ComponentMasterdataDiplomat'],
                },
                resolve: {
                  certifyingExamsAsReviewer: CertifyingExamsAsReviewerResolver,
                  certifyingExamsAsCredentialsChair: CertifyingExamsAsCredentialsChairResolver,
                  generalExamsAsReviewer: GeneralExamsAsReviewerResolver,
                  generalExamsAsCredentialsChair: GeneralExamsAsCredentialsChairResolver,
                },
              },

              {
                path: 'committee-work/review-certifying-examination/:id/:type',
                component: CertifyingExamReviewComponent,
                canActivate: [AudienceGuard, CertifyingExamReviewGuard],
                resolve: {
                  certifyingExam: CertifyingExamForReviewByIdResolver,
                },
                data: {
                  audience: ['ComponentMasterdataDiplomat'],
                },
              },

              {
                path: 'committee-work/review-general-examination/:id/:type',
                component: GeneralExamReviewComponent,
                canActivate: [AudienceGuard, GeneralExamReviewGuard],
                resolve: {
                  generalExam: GeneralExamForReviewByIdResolver,
                },
                data: {
                  audience: ['ComponentMasterdataDiplomat'],
                },
              },

              {
                path: 'examinations/application-certifying-examination',
                component: CertifyingExamApplicationSubmissionComponent,
                canActivate: [AudienceGuard],
                resolve: {
                  certifyingExams: CertifyingExamsForMemberResolver,
                  generalExams: GeneralExamsForMemberResolver,
                  page: PageResolverService,
                },
                data: {
                  allowUndefinedPage: true,
                },
              },

              {
                path: 'examinations/application-general-examination',
                component: GeneralExamApplicationSubmissionComponent,
                canActivate: [AudienceGuard],
                resolve: {
                  generalExams: GeneralExamsForMemberResolver,
                  page: PageResolverService,
                },
                data: {
                  allowUndefinedPage: true,
                },
              },

              {
                path: 'diplomate-activities/re-evaluation-online-submission',
                component: DiplomateReevaluationSubmissionComponent,
                canActivate: [AudienceGuard],
                resolve: {
                  diplomateReevaluations: DiplomateReevaluationsForMemberResolver,
                  page: PageResolverService,
                },
                data: {
                  allowUndefinedPage: true,
                },
              },

              {
                path: ':slug',
                children: [
                  {
                    matcher: (segments, group, route) => {
                      return {
                        consumed: segments,
                      };
                    },
                    component: CmsPageComponent,
                    canActivate: [AudienceGuard],
                    resolve: {
                      page: PageResolverService,
                    },
                  },
                ],
              },

              {
                path: '**',
                redirectTo: '/home',
                pathMatch: 'full',
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class RoutingModule {}
